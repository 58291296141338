import React from 'react';

export const Button = ({
  children,
  withMarginRight,
  fullOnPhone,
  asLink,
  block,
  bgColorClassName = 'bg-indigo-600 hover:bg-indigo-700',
  textColorClassName = 'text-white',
  className = '',
}) => {
  const buttonClassName = `px-2 h-12 inline-flex items-center justify-center ${
    withMarginRight ? 'mr-6' : ''
  } ${fullOnPhone ? 'w-full md:w-auto' : ''} ${
    block ? 'w-full' : ''
  } ${className} font-medium tracking-wide ${textColorClassName} transition duration-200 rounded shadow-md ${bgColorClassName} focus:shadow-outline focus:outline-none`;

  if (asLink) {
    return (
      <a href="/" className={buttonClassName}>
        {children}
      </a>
    );
  }

  return (
    <button type="submit" className={buttonClassName}>
      {children}
    </button>
  );
};
