import React from 'react';
import { Button } from '../components/Button';

export const SubscribeForm = ({ withLabel = true, onSubmit = () => {} }) => (
  <form
    method="POST"
    target="_blank"
    action="https://kitwind.us18.list-manage.com/subscribe/post?u=58a372bada5b2c977767bdf69&amp;id=055508a2ba"
    onSubmit={onSubmit}
  >
    {withLabel && (
      <label htmlFor="email" className="inline-block mb-2 font-semibold">
        Subscribe for new releases
      </label>
    )}
    <input
      id="email"
      autoComplete="email"
      name="EMAIL"
      required
      aria-required="true"
      placeholder="Your email"
      type="email"
      maxLength="150"
      className="flex-grow w-full h-12 px-4 mb-4 transition duration-200 bg-white border border-gray-400 rounded shadow-sm appearance-none focus:outline-none focus:shadow-outline focus:border-indigo-500"
      style={{
        WebkitAppearance: 'none',
      }}
    />
    <Button className="w-full mb-2">Subscribe</Button>
    <p className="text-sm text-gray-700">
      We respect your privacy. Unsubscribe at any time.
    </p>
  </form>
);
